export const constants = {
    CLICK_HERE_TXT: 'click here',
    BANNER_TXT_MSG: `Prolonged stress can affect your physical and mental health and wellbeing. As November 6th is Stress Awareness Day, please reach out to us if you are feeling stressed. All Saudi Aramco Contractors have access to free 24/7 multilingual mental well-being services including a call center, video counselling sessions, and immediate WhatsApp messaging connecting them with trained counselors. To access the Hot-line call 800-8500-619, to schedule a video session `,
    WHATSAPP_MSG: ` and to send a WhatsApp message `,
    ANALYTICS_EVENTS: {
        LGOIN_PAGE_VISIT: 'saaya_login_page_visit',
        LOGIN_BTN_CLICKED: 'saaya_login-btn-cliked',
        LANDING_PAGE_VISIT: 'saaya_landing_page_visit',
        ACCESS_CODE_SUCCESS: 'saaya_access_code_success',
        ACCESS_CODE_FAILURE: 'saaya_access_code_failure',
        FILTER_SELECTED: 'saaya_filter_selected',
        LANGUAGE_SELECTED: 'saaya_language_selected',
        CLICKED_RESOURCE: 'saaya_clicked_resource',
        CLICK_HERE_CLICKED: 'saaya_click_here_btn_cliked',
        CONTACT_US_BTN_CLICKED: 'saaya_contact_us_btn_clicked',
        VIDEO_CALL_BTN_CLICKED: 'saaya_video_call_btn_clicked',
        WHATSAPP_BTN_CLICKED: 'saaya_whatsapp_btn_clicked',
        RESOURCES_BTN_CLICKED: 'saaya_resources_btn_clicked',
        WHO_BTN_CLICKED: 'saaya_WHO5_btn-cliked',
        USE_BROWSER_LINK_BTN: 'saaya_use_browser_link_btn_clicked',
        DOWNLOAD_APP_LINK_BTN: 'saaya_download_app_link_btn_clicked',
        DOWNLOAD_IOS_APP_LINK_BTN: 'saaya_download_ios_app_link_btn_clicked',
    }
};